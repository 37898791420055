<template>
    <v-main class="custom--v-main">
        <app-header>
            <h3 class="tw-text-[25px] tw-text-[#0D3659] tw-font-semibold">
                Pricing & Services
            </h3>
        </app-header>

        <section class="price" v-show="isLoaded">
            <div class="price__tabs">
                <!-- Tabs -->
                <v-tabs v-model="tab" class="pricing-tabs">
                    <v-tab>Helpers Only</v-tab>
                    <v-tab>Helpers and Trucks</v-tab>
                    <v-tab>Service Fee</v-tab>
                    <v-tab>Calendar</v-tab>
                    <v-tab>Equipment pricing</v-tab>
                </v-tabs>

                <v-tabs-items class="custom--v-tabs-items" v-model="tab" :key="activeBranch.id" v-if="activeBranch">
                    <v-tab-item class="custom--v-tabs-item">
                        <pricing-helpers
                            tab-description="Set and list different crew sizes with each crew’s charging price per hour. Do include multiple crews with different prices and personnel in the team. The options you have set will be shown to customers when booking your services in an order you have selected."/>
                        <!--                        pricing service-->
                        <pricing-services
                            v-model="activeBranchMoversOnlyServicePrices"
                            :load-pricing="loadPricing"
                            :active-branch="activeBranch.id"
                            :movers_only="true"
                        />
                    </v-tab-item>
                    <v-tab-item>

                        <pricing-helpers-and-trucks
                            tab-description="Add your available trucks to your existing crews with changes on charges. You can also add new options with trucks and different prices and times. Down below you have options to set service and travel which you can alter according to your needs."/>

                        <pricing-services
                            v-model="activeBranchMoversAndTrucksServicePrices"
                            :load-pricing="loadPricing"
                            :active-branch="activeBranch.id"
                            :movers_only="false"
                        />
                    </v-tab-item>
                    <v-tab-item>
                        <pricing-service-fee
                            tab-description="Add extra services which are not included in your base service package and rate. This will be shown as an extra service with additional charges. Setting larger area of service can be added as an extra service."
                        />
                        <pricing-double-driving v-model="activeBranch.helpers_and_trucks_between_double_driving"
                                                @update="saveBranchForm"/>
                        <div class="service-fee__line"></div>
                        <pricing-service-area v-model="activeBranch"/>
                        <pricing-surge-rate v-model="activeBranch"/>
                        <pricing-travel-area v-model="activeBranch.travel_area"/>
                        <v-btn min-width="185" min-height="35" color="primary" @click="saveBranchForm" class="mb-8">
                            Save changes
                        </v-btn>
                    </v-tab-item>
                    <v-tab-item>
                        <pricing-charge-discount
                            :branch-id="activeBranch.id"
                            tab-description="Set certain dates to add extra charges for your services. These dates may include seasonal peak dates, holidays, or the days you have other orders. Add the days you discount your services with the amount or percentage of your charges."
                        />
                    </v-tab-item>
                    <v-tab-item>
                        <pricing-equipment
                            :branch="activeBranch"
                            tab-description="Add extra equipment that will not be included in your basic package. You can set a price for the equipment or you can add them as an extra bonus."
                        />
                    </v-tab-item>
                </v-tabs-items>
                <!-- Tabs end -->
            </div>
        </section>

        <app-loading-indicator
            v-show="!isLoaded"
        />
    </v-main>
</template>

<script>
import AppLocationSelector from "../../components/AppLocationSelector";
import { mapActions } from "vuex";
import loading from "../../mixins/loading";
import AppLoadingIndicator from "../../components/AppLoadingIndicator";
import PricingSurgeRate from "./PricingSurgeRate";
import PricingServiceFee from "./PricingServiceFee";
import PricingServiceArea from "./PricingServiceArea";
import PricingTravelArea from "./PricingTravelArea";
import PricingServices from "./PricingServices";
import PricingChargeDiscount from "./PricingChargeDiscount.vue";
import PricingPaymentMethods from "./PricingPaymentMethods.vue";
import PricingEquipment from "./PricingEquipment";
import PricingDoubleDriving from "@/views/Pricing/PricingDoubleDriving.vue";
import PricingHelpers from './PricingHelpers.vue';
import PricingHelpersAndTrucks from "./PricingHelpersAndTrucks.vue";

export default {
    mixins: [loading],

    components: {
        AppLocationSelector,
        AppLoadingIndicator,
        PricingSurgeRate,
        PricingServiceFee,
        PricingTravelArea,
        PricingServices,
        PricingServiceArea,
        PricingChargeDiscount,
        PricingPaymentMethods,
        PricingEquipment,
        PricingDoubleDriving,
        PricingHelpers,
        PricingHelpersAndTrucks
    },

    data () {
        return {
            activeBranchMoversOnlyServicePrices: [],
            activeBranchMoversAndTrucksServicePrices: [],
            activeBranchForm: null,
            tab: null,
        }
    },

    methods: {
        ...mapActions('service_pricing', ['index']),

        saveBranchForm () {
            this.startLoading(async () => {
                await this.$store.dispatch('locations/updateBranch', this.activeBranch)
            });
        },

        async loadPricing () {
            await this.startLoading(async () => {
                const { helpers_only, helpers_and_trucks } = await this.index(this.activeBranch.id);

                this.activeBranchMoversOnlyServicePrices = helpers_only;
                this.activeBranchMoversAndTrucksServicePrices = helpers_and_trucks;
            });
        },
    },

    computed: {
        canShowContainer () {
            return !! this.activeBranch && this.isLoaded;
        },

        activeBranch () {
            return this.$store.state.auth.activeBranch;
        }
    },

    created () {
        this.loadPricing();
    },

    watch: {
        activeBranch: 'loadPricing'
    },
};
</script>

<style lang="scss" scoped>
//tabs styles
@import "src/assets/scss/components/header-tabs";


.slider-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__div {
        width: 80px;
        padding: 5px 10px;
        margin-left: 40px;
        color: #0D3659;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        white-space: nowrap;
        border-radius: 8px;
        background-color: #757575;
    }
}

</style>
