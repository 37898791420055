<template>
    <v-main class="custom--v-main team">
        <app-header>
            <h3 class="tw-text-[25px] tw-text-[#0D3659] tw-font-semibold">
                Team
            </h3>
        </app-header>

        <v-container class="team-main">
            <div class="team-header">
                <h1 class="team-header__heading hidden-md-and-up text-secondary">Team</h1>
                <div>
                    <h2 class="team-header__subtitle text-xl font-semibold text-secondary mb-4">Members</h2>
                    <div class="d-flex justify-end">
                        <v-btn
                            class="px-10"
                            elevation="0"
                            color="primary"
                            large
                            @click="$router.push({name: 'team-member', params: {branch_id: activeBranch.id, crew_id: 'new' }})"
                        >
                            Add new member
                        </v-btn>
                    </div>
                </div>
                
            </div>

            <v-skeleton-loader v-if="isLoading" type="table"/>

            <section v-else-if="isLoaded && teams.length > 0" class="team-lists ps-0">
                <TeamsCard
                    v-for="team in teams"
                    :key="team.id"
                    :team="team"
                />
            </section>

            <v-alert v-else color="primary" text class="text-center">
                No members for this location.
            </v-alert>
        </v-container>
    </v-main>
</template>

<script>
import AppLocationSelector from "../../components/AppLocationSelector";
import { mapActions } from "vuex/dist/vuex.esm.browser";
import { mapState } from "vuex";
import loading from "@/mixins/loading";
import AppLoadingIndicator from "@/components/AppLoadingIndicator.vue";
import TeamsCard from "./TeamsCard.vue";

export default {
    components: {
    AppLocationSelector,
    AppLoadingIndicator,
    TeamsCard
},

    mixins: [loading],

    data () {
        return {
            /** @type {Crew[]} */
            teams: [],
        }
    },

    methods: {
        ...mapActions('team', ['index']),

        loadTeam () {
            this.$router.push({
                name: 'teams',
                params: {
                    branch_id: this.activeBranch.id,
                }
            });

            this.startLoading(async () => {
                this.teams = await this.index(this.activeBranch.id);
            });
        },

        getFullName(team) {
            return team.first_name + ' ' + team.last_name;
        }
    },

    computed: {
        ...mapState('locations', ['branches']),

        activeBranch () {
            return this.$store.state.auth.activeBranch;
        }
    },

    created () {
        this.loadTeam();
    },

    watch: {
        activeBranch () {
            this.loadTeam();
        },
    }
}
</script>

<style scoped lang="scss">
.team--container {
    padding: 30px;
}

.custom--v-simple-table {
    // border: 1px solid #ddd !important;
    // border-radius: 4px !important;

    // tr {
    //     &:nth-child(odd) {
    //         background-color: #FAFAFA !important;
    //     }

    //     &:hover {
    //         background-color: #F6F6F6 !important;
    //     }

    //     td {
    //         padding-top: 16px !important;
    //         padding-bottom: 16px !important;

    //         &:first-child {
    //             padding-left: 40px !important;
    //         }

    //         &:last-child {
    //             padding-right: 40px !important;
    //         }
    //     }
    // }

    tbody tr {
        background-color: #fff;
        border-radius: 8px;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}
</style>
