<template>
    <v-main class="calendar-main custom--v-main">
        <app-header class="calendar-header">
            <h3 class="tw-text-[25px] tw-text-[#0D3659] tw-font-semibold">
                Calendar
            </h3>
            <template v-slot:right>
                <div class="d-inline-flex align-center justify-end">
                    <div class="calendar-status-filters">
                        <app-calendar-switch color="#8F3985" label="New" v-model="onlyNewRequests"/>
                        <app-calendar-switch color="#8F3985" label="Accepted" v-model="onlyAccepted"/>
                        <app-calendar-switch color="#8F3985" label="Completed" v-model="onlyCompleted"/>
                        <app-calendar-switch color="#8F3985" label="Pending" v-model="onlyBeingRescheduled"/>
                    </div>
                </div>
                <div class="calendar-main__branch">
                    <v-btn depressed color="primary" class="app-selector v-btn v-size--medium mr-1"
                           @click="openBranchModal">
                        <span class="mr-auto">{{ branchName.three_dots(12) }}</span>
                        <v-icon size="8" class="ml-8">
                            {{ !isClickedBranch ? 'fa-chevron-right' : 'fa-chevron-down' }}
                        </v-icon>
                    </v-btn>
                    <app-location-selector
                        v-if="isClickedBranch"
                        @closeBranch="(isClose) => {isClickedBranch = isClose}"
                        @getBranchId="(id, name) => {branch = id; branchName = name}"
                        class="v-size--medium mr-5"/>
                </div>
            </template>


        </app-header>

        <v-container class="pa-lg-0" v-if="isLoaded">
            <v-row class="v-row--custom-mobile-responsive">
                <v-col class="pa-0" cols="12">
                    <div class="calendar-period-buttons">
                        <v-btn
                            v-for="item in typeToLabel" :key="item"
                            depressed
                            small
                            plain
                            v-text="item"
                            :text="type !== item.toLowerCase()"
                            v-bind:color="type === item.toLowerCase() ? 'secondary' : ''"
                            @click="type = item.toLowerCase()"
                        />
                    </div>
                    <div class="calendar-status-filters">
                        <div class="status-filters__item">
                            <span>New</span>
                            <v-switch hide-details v-model="onlyNewRequests"/>
                        </div>
                        <div class="status-filters__item">
                            <span>Accepted</span>
                            <v-switch hide-details v-model="onlyAccepted"/>
                        </div>
                        <div class="status-filters__item">
                            <span>Completed</span>
                            <v-switch hide-details v-model="onlyCompleted"/>
                        </div>
                        <div class="status-filters__item">
                            <span>Pending</span>
                            <v-switch hide-details v-model="onlyBeingRescheduled"/>
                        </div>
                    </div>
                    <calendar-daily-horizontal 
                        v-if="type === 'day'"
                        :selected-date="selectedWeekDay"
                        :orders="orders_2"
                        @setCurrentDay="setDays"
                    />
                    <calendar-weekly-horizontal 
                        v-else-if="type === 'week'"
                        :picker-date="pickerDate"
                        :orders="orders_2"
                        @selectWeekDay="selectWeekDay"
                        @setCurrentWeek="setDays"
                    />
                    <div class="calendar-date-picker" v-else-if="type === 'month'">
                        <v-date-picker
                            class="v-date-picker--custome"
                            v-model="pickerDate"
                            :picker-date.sync="pickerDate"
                            :event-color="datePickerColorEvents"
                            :events="datePickerEvents"
                            color="primary"
                        ></v-date-picker>
                    </div>

                    <div class="calendar-schedule">
                        <div class="calendar-schedule__title">Schedule</div>
                        <div class="calendar-schedule__body">
                            <div 
                                class="schedule-item"
                                v-for="scheduleItem in orders_2"
                                :key="scheduleItem.dayNumber"
                            >
                                <div class="left-side">
                                    <div class="schedule-day">{{ scheduleItem.dayNumber }}</div>
                                    <div class="schedule-weekday">{{ scheduleItem.dayName }}</div>
                                    <div class="schedule-dot"></div>
                                </div>
                                <div class="right-side">
                                    <div 
                                        class="right-side__block"
                                        v-for="order in scheduleItem.orders"
                                        :key="order.id"
                                        :style="`background-color: ${setWeekdayColor(scheduleItem.dayName)};`"
                                    >
                                        <div class="schedule-fullname">{{ order.name }}</div>
                                        <div class="d-flex">
                                            <div class="schedule-time">
                                                <v-icon>fa-clock</v-icon>
                                                <span>{{ order.arrival_time }}</span>
                                            </div>
                                            <div class="schedule-id">#{{ order.id }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <i v-if="orders_2.length === 0">No available orders.</i>
                    </div>
                </v-col>
            </v-row>
            <v-row class="v-row--custom">
                <v-col>
                    <v-sheet class="calendar-main__header" height="64">
                        <v-toolbar flat class="v-custom--toolbar">
                            <div class="calendar-period-buttons">
                                <v-btn
                                    v-for="item in typeToLabel" :key="item"
                                    depressed
                                    small
                                    v-text="item"
                                    :text="type !== item.toLowerCase()"
                                    v-bind:color="type === item.toLowerCase() ? 'secondary' : 'secondary'"
                                    @click="type = item.toLowerCase()"
                                />
                            </div>
                            <v-spacer/>
                            <div class='d-flex align-center'>
                                <v-btn
                                    fab
                                    text
                                    small
                                    class="mr-4"
                                    color="secondary"
                                    @click="prev"
                                >
                                    <v-icon small>fa-chevron-left</v-icon>
                                </v-btn>
                                <v-toolbar-title v-if="$refs.calendar">
                                <span class="font-weight-bold">
                                    {{ $refs.calendar.title }}
                                </span>
                                </v-toolbar-title>

                                <v-toolbar-title v-else>
                                <span class="font-weight-bold">
                                    {{ getTitle }}
                                </span>
                                </v-toolbar-title>
                                <v-btn
                                    fab
                                    text
                                    small
                                    class="ml-4"
                                    color="secondary"
                                    @click="next"
                                >
                                    <v-icon small>fa-chevron-right</v-icon>
                                </v-btn>
                            </div>

                            <v-spacer/>
                            <div class="calendar-today-button">
                                <v-btn
                                    outlined
                                    small
                                    color="grey lighten-1"
                                    @click="viewDay"
                                >
                                    <v-icon color="secondary" class="mr-5" small>fa-chevron-left</v-icon>
                                    <span class="text-secondary">Today</span>
                                    <v-icon color="secondary" small class="ml-5">fa-chevron-right</v-icon>
                                </v-btn>
                            </div>
                        </v-toolbar>
                    </v-sheet>
                    <v-sheet class="v-sheet--calendar mr-4">
                        <v-calendar
                            ref="calendar"
                            v-model="focus"
                            :short-weekdays="false"
                            :events="orders"
                            :type="type"
                            :weekdays="weekdays"
                            :first-interval="7"
                            :interval-minutes="60"
                            :interval-count="10"
                            :interval-height="100"
                            @click:event="showEvent"
                            @click:more="viewDay"
                            @click:date="viewDay"
                            @contextmenu:time="onContextMenuClick"
                            @change="onChange"
                            :event-color="getEventColor"
                            :class="{'monthly-calendar': type === 'month', 'weekly-calendar': type === 'week', 'day-calendar': type === 'day'}"
                        >
                            <template v-slot:event="{ event }">
                                <div class="calendar-events">
                                    <strong>
                                        <span>{{ event.name }}</span>
                                    </strong>
                                    <strong v-show="type !== 'month'">
                                        <span>#{{ event.id }}</span>
                                    </strong>
                                    <span v-show="type !== 'month'">
                                        <v-icon>fa-phone-alt</v-icon>
                                        <span>{{ event.customer_phone.replace(/[-() ]/g, "") }}</span>
                                    </span>
                                    <span v-show="type !== 'month'">
                                        <v-icon>fa-clock</v-icon>
                                        <span>{{ event.arrival_time }}</span>
                                    </span>
                                    <span v-show="type !== 'month'" v-if="event.service === 'Helpers And Trucks'">
                                        <v-icon>fa-truck</v-icon>
                                        <span>{{ event.service }}</span>
                                    </span>
                                    <span v-show="type !== 'month'">
                                        <v-icon>fa-users</v-icon>
                                        <span>{{ $pluralize(event.helpers_count, 'helper') }}</span>
                                    </span>
                                </div>
                            </template>

                            <template v-slot:interval="a">
                                <div
                                    :class="[{'calendar-blocked-day': isIntervalBlocked(a)}]"
                                />
                            </template>
                        </v-calendar>

                        <v-menu
                            v-model="showContextMenu"
                            :position-x="contextMenuX"
                            :position-y="contextMenuY"
                            absolute offset-y
                        >
                            <v-list dense>
                                <v-list-item link @click="blockDayModal">
                                    <v-list-item-title>
                                        {{ contextBlockDay ? 'Edit Blocked Time' : 'Block Off Time' }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>

        <app-loading-indicator v-else/>
    </v-main>
</template>

<script>
import AppCalendarSwitch from '../../components/AppCalendarSwitch.vue';
import { mapActions, mapState } from "vuex";
import { ORDER_COLORS, ORDER_STATUSES, WEEK_DAY_SHORT_NAMES } from "@/plugins/data.js";
import loading from "../../mixins/loading";
import AppLoadingIndicator from "../../components/AppLoadingIndicator";
import AppOrder from "../../components/Modals/AppOrder";
import CalendarBlockDay from "@/views/Calendar/CalendarBlockDay.vue";
import dayjs from "dayjs";
import isToday from 'dayjs/plugin/isToday';
import AppLocationSelector from "@/components/AppLocationSelector";
import CalendarWeeklyHorizontal from './CalendarWeeklyHorizontal.vue';
import CalendarDailyHorizontal from './CalendarDailyHorizontal.vue';

export default {
    components: {
        AppCalendarSwitch,
        AppLoadingIndicator,
        AppLocationSelector,
        CalendarWeeklyHorizontal,
        CalendarDailyHorizontal
    },

    mixins: [ loading ],

    data: () => ({
        focus: '',
        isClickedBranch: false,
        branchName: "All Branches",
        type: 'week',
        typeToLabel: {
            day: 'Day',
            week: 'Week',
            month: 'Month',
        },
        events: [],
        weekdays: [ 1, 2, 3, 4, 5, 6, 0 ],

        onlyNewRequests: false,
        onlyAccepted: true,
        onlyCompleted: false,
        onlyBeingRescheduled: false,

        showContextMenu: false,
        contextSelectedDate: null,
        contextBlockDay: {},
        contextMenuX: null,
        contextMenuY: null,
        branch: 'company',
        
        pickerDate: dayjs().format("YYYY-MM-DD"),
        selectedWeekDay: null,
        days: []
    }),

    mounted() {
        this.loadCalendar();
    },

    methods: {
        ...mapActions('calendar', [ 'load', 'loadBlockedDays', 'storeBlockedDay' ]),

        /**
         * @param {PointerEvent} nativeEvent
         * @param {string} date
         * @param {Boolean} past
         */
        onContextMenuClick({ nativeEvent, date, past, hour }) {
            dayjs.extend(isToday);
            const notToday = !dayjs(date).isToday();

            if (past && notToday) return false;

            // check if the date is blocked
            this.contextBlockDay = this.isIntervalBlocked({ date, hour });

            this.showContextMenu = false;

            this.contextMenuX = nativeEvent.clientX;
            this.contextMenuY = nativeEvent.clientY;

            this.$nextTick(() => {
                this.contextSelectedDate = date;
                this.showContextMenu = true;
            });
        },

        blockDayModal() {
            this.$modal.show(CalendarBlockDay, {
                date: this.contextSelectedDate,
                isCreating: !this.contextBlockDay,
                activeBranchId: this.branch,
                blockDay: { ...this.contextBlockDay },
            }, {
                width: '850px',
                height: '500px',
            });

            this.showContextMenu = false;
        },

        isIntervalBlocked({ date, hour }) {
            return this.blocked_days.find(blocked_day => {
                if (blocked_day.date !== date) {
                    return false;
                }

                const isOnMorning = blocked_day.is_on_morning && hour < 12;
                const isOnAfternoon = blocked_day.is_on_afternoon && hour >= 12;

                return isOnMorning || isOnAfternoon;
            });
        },

        viewDay({ date }) {
            this.focus = date
            this.type = 'day'
        },

        prev() {
            this.$refs.calendar.prev()
        },

        next() {
            this.$refs.calendar.next()
        },

        showEvent({ event: { id: orderId } }) {
            this.$modal.show(AppOrder, {
                orderId,
            }, {
                width: '800px',
                height: '660px',
            });
        },

        onChange() {
            this.$refs.calendar.scrollToTime('08:45')
        },

        getEventColor(event) {
            const eventDayName = dayjs(event.arrival_date).format("ddd");
            const dayNameIndex = WEEK_DAY_SHORT_NAMES.indexOf(eventDayName);
            return ORDER_COLORS[dayNameIndex];
        },

        async loadCalendar() {
            await this.startLoading(async () => {
                await this.load(this.branch)
                await this.loadBlockedDays();
            });
        },

        openBranchModal() {
            this.isClickedBranch = !this.isClickedBranch
        },

        selectWeekDay(day) {
            this.selectedWeekDay = day;
            this.type = 'day';

            // Setting day for filtering orders: 
            this.setDays([day])
        },

        setDays(days) {
            this.days = [];
            days.forEach(({ date }) => {
                this.days.push({ 
                    date: dayjs(date).format("YYYY-MM-DD"), 
                    dayName: dayjs(date).format("ddd"), 
                    dayNumber: dayjs(date).format("DD")  
                });
            })
        },

        setWeekdayColor(weekdayShortName) {
            const index = WEEK_DAY_SHORT_NAMES.indexOf(weekdayShortName);
            return ORDER_COLORS[index];
        },

        datePickerEvents (date) {
            const [,, day] = date.split('-')
            const orderedDays = this.orders_2.map(order => Number(order.dayNumber));
            if (orderedDays.includes(parseInt(day, 10))) return true
            return false
        },

        datePickerColorEvents (date) {
            const weekdayName = dayjs(date).format("ddd")
            return this.setWeekdayColor(weekdayName);
        }
    },

    computed: {
        ...mapState('calendar', [ 'blocked_days' ]),

        orders() {
            const filters = [];

            if (this.onlyNewRequests) filters.push(ORDER_STATUSES.STATUS_NEW);
            if (this.onlyAccepted) filters.push(ORDER_STATUSES.STATUS_ACCEPTED);
            if (this.onlyCompleted) filters.push(ORDER_STATUSES.STATUS_COMPLETED);
            if (this.onlyBeingRescheduled) filters.push(ORDER_STATUSES.STATUS_BEING_RESCHEDULED);

            return this.$store.getters["calendar/orders"](filters);
        },

        orders_2() { // Orders for mobile responsive calendar
            const orders = [];
            if (this.type === 'month') {
                // Group by arrival_date:
                if(this.orders.length) {
                    const ordersGroupedBy = this.orders.reduce((result, obj) => {
                        // Apply the filter here (in this case, filter objects with value > 20)
                        if (this.pickerDate.split("-")[1] === obj.arrival_date.split("-")[1]) {
                          // Check if the category already exists in the result object
                          if (result[obj.arrival_date]) {
                            // If category exists, push the object into its array
                            result[obj.arrival_date].push(obj);
                          } else {
                            // If category doesn't exist, create a new array with the object
                            result[obj.arrival_date] = [obj];
                          }
                        }
                    
                        return result;
                    }, {});

                    if (ordersGroupedBy) {
                        const keysOfOrdersGroupedBy = Object.keys(ordersGroupedBy);
                        keysOfOrdersGroupedBy.forEach(arrivalDate => {
                            orders.push({
                                dayName: dayjs(arrivalDate).format("ddd"),
                                dayNumber: dayjs(arrivalDate).format("DD"),
                                date: arrivalDate,
                                orders: ordersGroupedBy[arrivalDate]
                            });
                        });
                    };
                }
            }
            else {
                this.days.forEach(day => {
                    const innerOrders = [];
                    this.orders.forEach(order => {
                        if (day.date === order.arrival_date) {
                            innerOrders.push(order);
                        }
                    })
                    if (innerOrders.length > 0) {
                        orders.push({
                            ...day,
                            orders: innerOrders
                        })
                    }
                })
            }
            
            return orders.sort((a, b) => dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1);
        },

        getTitle() {
            return dayjs().format('MMMM YYYY');
        }
    },

    watch: {
        branch: 'loadCalendar',
        pickerDate() {
            this.selectedWeekDay = {
                day: Number(dayjs(this.pickerDate).format("DD")),
                monthIndex: Number(dayjs(this.pickerDate).format("MM")) - 1,
                year: Number(dayjs(this.pickerDate).format("YYYY"))
            }

            // this.pickerDate can be "YYYY-MM" or "YYYY-MM-DD" formats.
            // If it is in "YYYY-MM-DD" format, any a day was selected or clicked in v-date-picker component:
            if (this.pickerDate.split('-').length === 3) {
                this.type = "day";
            }
        }
    },
}
</script>

<style lang="scss" scoped>

@import "src/assets/scss/pages/calendar/calendar";

.v-event {
    padding: 3px 10px;
}

.v-btn--fab {
    margin-bottom: 5px;
}

.calendar-events {
    display: flex;
    flex-direction: column;
    font-size: 12px;

    & > strong {
        font-weight: 600;
        margin: 2px 0;

        span {
            white-space: pre-wrap;
            word-break: break-all;
        }
    }

    & > span {
        display: flex;
        font-weight: 500;
        color: rgba(#FFF, 0.7);
        margin: 2px 0;

        span {
            line-height: 1.2;
            white-space: pre-wrap;
            word-break: break-all;
        }
    }

    .v-icon {
        color: rgba(#FFF, 0.7);
        font-size: 11px;
        margin-right: 6px;
    }
}

.calendar-blocked-day {
    height: 100%;
    background: #fca6a6;
}

.app-selector {
    width: 170px;
}
</style>
